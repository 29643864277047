var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-3 mb-5"},[_c('v-text-field',{attrs:{"label":"Automation name","outlined":""},on:{"input":_vm.update},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", $$v)},expression:"value.name"}}),_c('v-row',{staticClass:"mx-0 mb-3"},[_c('v-btn',{staticClass:"elevation-0 ml-auto",attrs:{"color":"success"},on:{"click":_vm.createaction}},[_vm._v(" Add action")])],1),_c('v-card',{staticClass:"mb-3"},[_c('v-card-title',[_vm._v(" Event ")]),_c('div',{staticClass:"pb-3"},[_c('v-select',{staticClass:"mx-3 mb-3",attrs:{"outlined":"","label":"Type","items":_vm.dtypes,"item-text":"name","item-value":"value","hide-details":"auto"},on:{"change":_vm.update},model:{value:(_vm.value.eventtype),callback:function ($$v) {_vm.$set(_vm.value, "eventtype", $$v)},expression:"value.eventtype"}}),(_vm.value.eventtype)?_c('div',{staticClass:"mx-3"},[_c('p',{staticClass:"blue--text text-h5 font-weight-bold"},[_vm._v("Variables")]),_c('p',{staticClass:"green--text mt-n4",staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(Object.entries(_vm.dtypes.find(function (s) { return s.value === _vm.value.eventtype; }).data) .map(function (ref) {
	var d = ref[0];
	var k = ref[1];

	return ("%" + d + "%: " + k);
}) .join("\n"))+" ")])]):_vm._e()],1)],1),_c('draggable',{model:{value:(_vm.value.actions),callback:function ($$v) {_vm.$set(_vm.value, "actions", $$v)},expression:"value.actions"}},_vm._l((_vm.value.actions),function(action,i){return _c('v-card',{key:i,class:"mb-2 native hover"},[_c('span',{staticClass:"grippy ml-3 mb-n4 mt-n5"}),_c('v-card-title',[_vm._v(" Action "+_vm._s(action.id)+" ")]),_c('div',{staticClass:"mx-3 pb-4"},[_c('v-select',{attrs:{"label":"action type","items":_vm.atypes,"outlined":"","item-text":"name","item-value":"value","hide-details":"auto"},on:{"change":_vm.update},model:{value:(action.actionType),callback:function ($$v) {_vm.$set(action, "actionType", $$v)},expression:"action.actionType"}}),(action.actionType)?_c('div',[_vm._l((_vm.fetchFields(action, 'dropdown')),function(field,i){return _c('v-select',{key:i,staticClass:"mt-3",attrs:{"items":field.options,"label":field.name,"item-text":"name","hide-details":"auto","outlined":""},on:{"change":_vm.update},model:{value:(action.data[field.value]),callback:function ($$v) {_vm.$set(action.data, field.value, $$v)},expression:"action.data[field.value]"}})}),_vm._l((_vm.fetchFields(action, 'text')),function(field,i){return _c('v-text-field',{key:i,staticClass:"mt-3",attrs:{"label":field.name,"hide-details":"auto","outlined":""},on:{"input":_vm.update},model:{value:(action.data[field.value]),callback:function ($$v) {_vm.$set(action.data, field.value, $$v)},expression:"action.data[field.value]"}})}),_vm._l((_vm.fetchFields(action, 'longtext')),function(field,i){return _c('v-textarea',{key:i,staticClass:"mt-3",attrs:{"label":field.name,"hide-details":"auto","outlined":""},on:{"input":_vm.update},model:{value:(action.data[field.value]),callback:function ($$v) {_vm.$set(action.data, field.value, $$v)},expression:"action.data[field.value]"}})})],2):_vm._e()],1)],1)}),1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":_vm.toast.color,"text":""},on:{"click":function($event){_vm.toast.visible = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.toast.visible),callback:function ($$v) {_vm.$set(_vm.toast, "visible", $$v)},expression:"toast.visible"}},[_vm._v(" "+_vm._s(_vm.toast.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }